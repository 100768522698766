import React from "react";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { onAuthStateChanged } from "firebase/auth";
import ContextGeneral from "../../servicios/contexto";

import "./home.scss";
import About from "../../componentes/about-me/about-me";
import Beneficios from "../../componentes/beneficios/Beneficios";
import NoticiaChica from "../../componentes/noticiaChica/noticiaChica";
import Info from "../../componentes/info/Info";

import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { FaTwitch, FaArrowRight } from "react-icons/fa";
import Youtube from "../../componentes/youtube/Youtube";
function Home() {
  const navigate = useNavigate();
  const context = useContext(ContextGeneral);
  const { setEstadoUsuario, inspectorSesion, llamadaDB } =
    useContext(ContextGeneral);

  useEffect(() => {
    llamadaDB();
    setEstadoUsuario(0);
    onAuthStateChanged(context.auth, inspectorSesion);
    window.scroll(0, 0);
    document.title = "Sebas Sotelo | Desarrollador Web";
  }, []);

  return (
    <>
      <div className="home">
        <div className="home__container">
          <div className="title__mobile">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="home__title">
                <h1 className="title__h1">Desarrollador</h1>
                <h2 className="title__h2"></h2>
                <h2 className="title__h2">
                  <span>WEB</span> Fullstack
                </h2>
                <p className="title__p">
                  Te lo muestro en{" "}
                  <a
                    href="https://www.youtube.com/channel/UCjnn1S2FKvvkicwEuoBlmAQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineYoutube />
                  </a>
                  y
                  <a
                    href="https://www.twitch.tv/sebassdev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitch style={{ fontSize: "26px" }} />
                  </a>
                </p>
                <p className="title__p">Autodidacta y Freelancer.</p>

                <div className="title__links">
                  <p className="title__a" onClick={() => navigate("/sobre-mi")}>
                    Sobre Mi
                  </p>
                  <p
                    className="title__a title__a__portfolio scale-up-center"
                    onClick={() => navigate("/sobre-mi#portfolio")}
                  >
                    Mis Proyectos
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
          {/* <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="youtube__container"
          >
            {context.loader && (
              <div className="home__youtube">
                <h3 className="title__h3">Ultimo Video</h3>
                <iframe
                  width="280"
                  height="500"
                  src="https://www.youtube.com/embed/wqrVzQFDwtg?si=7pNTEt0emqiS9r72?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
                <p
                  className="ver__mas__video"
                  onClick={() => navigate("/videos")}
                >
                  Ver mas Videos
                </p>
              </div>
            )}
          </motion.div> */}
        </div>
      </div>

      {/* PORTAFOLIOOO*/}
      {/* <div className="home__beneficios">
        <Beneficios />
      </div> */}

      <Info />

      <div className="free">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1.5 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <div className="free__container">
            <div className="free__contenido">
              <div className="free__text">
                <h4>Quieres desarrollar una idea?</h4>
                <p>
                  Contactame,{" "}
                  <span onClick={() => navigate("/freelance")}>
                    la realizamos
                  </span>{" "}
                </p>
              </div>
              <p
                onClick={() => navigate("/freelance#form")}
                className="contacto"
              >
                Contactame <FaArrowRight className="icon" />
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      <Youtube />
    </>
  );
}

export default Home;
